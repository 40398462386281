.submissionLink {
  display: inline-block;
  padding: .7rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  background-color: #f58e6f;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  max-width: 200px;
  z-index: 10;
  margin-bottom: 0.8rem;
}

.submissionLink:hover {
  background-color: #ff7f50;
  transform: translateY(-2px);
}

.submissionLink:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .submissionLink {
    padding: 0.5rem 1rem;
    min-width: 120px;
    max-width: 200px;
  }
}

.footerContainer {
  position: fixed;
  bottom: 0.6rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

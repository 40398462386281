@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.backgroundAnimation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: linear-gradient(-45deg, #2196f3, #89ff8d, #03a9f4, #ecfc75); */
  background-size: 400% 600%;
  animation: gradientShift 15s ease infinite;
  z-index: -1;
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.title {
  font-size: 3.5rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 2px 4px 20px rgba(0,0,0,0.3);
  margin-bottom: 0.5rem;
  font-family: var(--title-font);
}

.description {
  font-size: 1.2rem;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  margin-top: 0;
  font-family: var(--body-font);
}


.loadingMessage,
.noMoreMessage,
.errorMessage {
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
}

.errorMessage button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff9800;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.errorMessage button:hover {
  background-color: #f57c00;
}

.artifactListContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
  width: 90vw;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.artifactList {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 2rem; /* Add some padding at the bottom */
}

.artifactList > * {
  min-height: 250px; /* Adjust this value as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filtering {
  opacity: 0.6;
}

.description a {
  color: #f58e6f;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.4s ease;
}

.claudeLink {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.claudeLogo {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  fill: #f58e6f;
  transition: all 0.3s ease;
}

.description a:hover, .claudeLink:hover .claudeLogo {
  color: #ebfbfe;
  cursor: pointer;
  fill: #ebfbfe;
  text-shadow: 2px 2px 4px rgba(62, 62, 62, 0.3);
  transition: all 0.3s ease;
}

.description a:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease;
}

.loadingMessage,
.noMoreMessage,
.errorMessage {
  text-align: center;
  color: #ffffffa1;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  font-family: Georgia, 'Times New Roman', Times, serif;
  transition: all 0.3s ease;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  transition: all 0.2s ease;
}

.fixed {
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: rgba(22, 22, 22, 0.99);
  box-shadow: 0 6px 10px rgba(158, 158, 158, 0.1);
  transition: all 0.1s ease;
}


.categoryFilterContainer {
  position: relative;
  visibility: visible;
}

.categoryFilterButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 48px;
  height: 48px;
}

.categoryFilterButton:hover,
.categoryFilterButton:focus {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
  outline: none;
}

.categoryIcon {
  width: 28px;
  height: 28px;
  fill: currentColor;
}

.categoryDropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: rgba(41, 41, 41, 0.95);
  border-radius: 8px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.categoryDropdown.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
}

.categoryButton {
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  white-space: nowrap;
}

.categoryButton:hover {
  background-color: rgba(97, 218, 251, 0.1);
  /* color: #61dafb; */
}

.categoryButton.active {
  background-color: rgba(97, 218, 251, 0.2);
  color: #61dafb;
  font-weight: 500;
}

.controls input {
  flex-grow: 1;
  max-width: 200px;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: .rem;
  font-family: var(--body-font);
}

.controls input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.highlightSection {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0.5rem;
  margin-bottom: .25rem;
}

.highlightScroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 0.25rem 0;
  width: 100%;
  border-radius: 10px;
  scrollbar-width: thin;
  scrollbar-color: #6d6d6d #373737;
  -webkit-overflow-scrolling: touch;
}

.highlightScroll::-webkit-scrollbar {
  height: 6px;
}

.highlightScroll::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
  border-radius: 3px;
}

.highlightScroll::-webkit-scrollbar-track {
  background-color: #373737;
}

.highlightCard {
  flex: 0 0 auto;
  width: 280px;
  margin-right: 1rem;
  scroll-snap-align: start;
  background: rgba(158, 201, 201, 0.1);
  border-radius: 15px;
  padding: .2rem;
  transition: all 0.2s ease;
}

.highlightCard:last-child {
  margin-right: 0.5rem;
}

.highlightCard:hover {
  background: rgba(88, 115, 115, 0.);
  
  transition: all 0.2s ease;
}

.artifactListContainer {
  padding: 0 0.5rem;
}

.weeklyHighlights {
  position: relative;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  color: #fff;
}

.highlightImage {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.highlightInfo {
  flex: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlightInfo h3 {
  font-size: 1rem;
  margin: 0 0 0.25rem;
  color: #fff;
}

.highlightDescription {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: .3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.highlightMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

.voteCount {
  display: flex;
  align-items: center;
  color: #fff;
}

.voteCount svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: currentColor;
}

.categoryBadge {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.7rem;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  width: 48px; 
  height: 48px; 
  transition: all 0.3s ease;
}

.iconButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  
}

.iconButton svg {
  width: 30px; 
  height: 30px; 
  fill: currentColor;
}

.sortArrow {
  width: 16px; 
  height: 16px; 
  position: absolute;
  transform: translateY(80%);
  scale: 0.8;
}

.sortArrow.up {
  transform: rotate(180deg) translateY(80%);
}

.categoryDropdown {
  position: absolute;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.categoryDropdown:hover {
  border-color: #aaa;
}

.categoryDropdown:focus {
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlayContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .highlightSection {
    display: flex;
    justify-content: center;
  }

  .highlightScroll {
    max-width: 1200px;
    justify-content: center;
  }
}

@media (max-width: 1199px) {
  .highlightScroll {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .highlightCard {
    width: 85%;
    min-width: 250px;
  }

  .highlightScroll {
    padding: 0.25rem 0.5rem;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .artifactList {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 4rem;
  }

  .artifactListContainer {
    max-width: 100%;
    padding: 0 1rem;
  }

  .title {
    font-size: 2.2rem;
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .highlightSection {
    padding: 0.2rem;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .controls {
    padding: 0.5rem;
    transition: all 0.2s ease;
  }
}



@media (max-width: 480px) {
  .title {
    font-size: 2.2rem;
  }

  .artifactList {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .artifactListContainer {
    padding: 0;
    margin: 0;
  }

  .highlightSection {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: left;
  }

  .highlightCard {
    width: 90%;
    min-width: 220px;
  }

  .controls {
    padding: 0.2rem;
    gap: 0.2rem;
  }

  .iconButton {
    width: 40px;
    height: 40px;
  }

  .iconButton svg {
    width: 20px;
    height: 20px;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

/* :root {
  --primary-color: #2196f3;
  --secondary-color: #4caf50;
  --accent-color: #ff9800;
  --background-color: #f5f5f5;
  --text-color: #333333;
  --light-text-color: #ffffff;
  --dark-text-color: #222222;
  --font-family: 'Outfit', sans-serif;
  --body-font: 'Poppins', sans-serif;
  --title-font: 'Outfit', sans-serif;
} */

appPreview:focus {
  outline: none;
  animation: pulse 0.5s ease-in-out;
}

/* New classes for JustBuildApps redesign */
.title {
  font-family: 'Outfit', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: var(--light-text-color);
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  margin-bottom: 0.5rem;
}

.description {
  font-family: 'Outfit', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--light-text-color);
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  margin-bottom: 1rem;
}

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(-45deg, #2196f3, #4caf50, #03a9f4, #8bc34a);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  color: var(--text-color);
  min-height: 100vh;
  font-family: 'Outfit', sans-serif;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.appPreview {
  position: relative;
  background: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row; /* Change to row to allow side-by-side layout */
  height: 100%;
  max-height: 37vh;
  transition: transform 0.2s ease-in-out;
}

.appPreview:hover {
  transform: translateY(-5px);
}

.thumbnailContainer {
  position: relative;
  flex-grow: 1;
  min-height: 200px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0; /* Ensure no margin */
}

.thumbnailZoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
  transform: scale(1.2); /* Slight zoom by default */
}

.thumbnailContainer:hover .thumbnailZoom {
  transform: scale(1.15); /* Increased zoom on hover */
}

/* Optional: Add a subtle overlay on hover for better visibility */
.thumbnailContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnailContainer:hover::after {
  opacity: 1;
}

.appPreviewContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  margin: 0; /* Ensure no margin */
}

.appPreviewInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
  margin: 0; /* Ensure no margin */
}

.appPreviewName {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryBadge {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  color: #ffffff;
  background-color: #5d5d5de6;
  border-radius: 1rem;
  white-space: nowrap;
  font-weight: 500;
  max-width: 90px;
  text-shadow: none;
  z-index: 1;
}

.voteContainer {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 30px;
  transform: translateY(-60%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 5px 5px;
  z-index: 20;
}

.voteButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  padding: 5px;
  color: #ffffff;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voteButton:hover {
  border: 2px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.voteCount {
  font-size: 1rem;
  font-weight: 600;
  margin: 5px 0;
}

.highlighted {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.highlightBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffd700;
  color: #000000;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.appPreviewLink {
  flex-grow: 1; /* Allow this to grow and push the info bar down */
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.appPreviewContent {
  width: 100%;
  height: 100%;
}

.appPreviewInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
  margin: 0; /* Ensure no margin */
}

.appPreviewHeader {
  display: flex;
  align-items: center;
  flex: 1;
}

.appPreviewName {
  font-size: 1.05rem;
  color: #ffffff;
  font-weight: 400;
  padding: 5px;
  font-family: var(--font-family);
}

.appPreviewMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .appPreview {
    flex-direction: column;
    max-height: none;
  }

  .voteContainer {
    right: 5px;
    padding: 5px;
  }

  .voteButton {
    margin: 0 5px;
  }

  .voteCount {
    margin: 0 10px;
  }

  .thumbnailContainer {
    height: auto;
    aspect-ratio: 16 / 9; /* Maintain aspect ratio on mobile */
  }
}

@media (max-width: 480px) {
  .thumbnailContainer {
    height: 120px; /* Keep the fixed height for very small screens */
  }
  .appPreviewName {
    font-size: 1.1rem;
  }
  .appPreviewUrl {
    font-size: 0.8rem;
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.highlighted {
  border: 2px solid #ffd700;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.highlightBadge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffd700;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8em;
}

.app-preview, .controls, .highlightSection {
  margin-bottom: 1rem;
  padding: 1rem;
}

.appLink {
  display: block;
  text-decoration: none;
  color: inherit;
}

.appLink:hover {
  text-decoration: none;
}
@import url('https://fonts.googleapis.com/css2?family=Google+Sans:wght@300;400;700&family=Poppins:wght@300;400;700&family=Outfit:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

body, #root {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #202020ec;
  color: #fff;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  /* max-width: 100%; */
}

.container, .artifactListContainer {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

h1, h2, h3 {
  text-align: center;
  padding: 0px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin: 0;
}

p {
  color: #fff;
  text-align: center;
  padding: 20px;
}

.artifact-list {
  display: grid;
  gap: 20px;
  justify-items: center;
  margin: 10px auto;
}

.app-preview {
  width: 100%;
  max-width: 300px;
  border: 2px solid #000;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease;
  background-color: #fff;
  padding: 10px;
  margin: 10px;
}

.app-preview:hover {
  box-shadow: 0 0 10px rgba(255,255,255,0.1);
}

.app-preview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid #000;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin: 10px;
}

.app-preview-image {
  height: 150px;
  overflow: hidden;
}

.app-preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app-preview-url {
  padding: 10px;
  font-size: 0.9rem;
  text-align: center;
  word-break: break-all;
  color: #000;
}

.error-message {
  color: #ff0000;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 50px;
}

.loading-message,
.no-more-message {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 20px;
  color: #fff;
}

footer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #010202;
  color: #fff;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  bottom: 0;
  z-index: 1000;
}

footer p {
  margin: 0;
  padding-left: 16px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.social-icons a {
  color: #ffffff;
  font-size: 24px;
  margin: 0 1.5rem;
  text-decoration: none;
  padding: 2px;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  color: #61dafb;
  transform: scale(1.2);
  transition: all 0.3s ease;
}

#footlink {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.2s ease;
  padding: 8px 16px;
  border-radius: 20px;
  flex: 1;
  text-align: right;
}

#footlink:hover {
  transform: scale(1.05);
  transition: all 0.2s ease;
}

@media (max-width: 768px) {
  footer {
    flex-wrap: wrap;
    height: auto;
    padding: 10px 20px;
  }

  footer p {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .social-icons {
    flex-basis: 100%;
    justify-content: center;
  }

  #footlink {
    flex-basis: 100%;
    text-align: center;
  }

  .social-icons a {
    margin: 0 1.3rem;
  }
}

@media (max-width: 480px) {
  .social-icons {
    bottom: 0px;
  }

  .social-icons a {
    margin: 0 13px;
  }
}
.container {
  max-width: 1400px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #f7fafc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.title {
  position: absolute;
  top: 7vh;
  font-family: var(--title-font);
  font-size: 3rem;
  font-weight: 700;
  color: #1a202c;
  margin-bottom: 2rem;
  text-align: center;
}

.creatorGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.creatorCard {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  /* border: 1px solid #e2e8f0; */

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-color: #4a5568;
  }
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  object-fit: cover;
  border: 3px solid #3498db;
}

.username {
  font-family: var(--body-font);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 0.5rem;
  color: #1a202c;
}

.email {
  font-size: 14px;
  color: #7f8c8d;
  margin: 0 0 10px;
}

.bio {
  font-family: var(--body-font);
  font-size: 1rem;
  color: #4a5568;
  margin: 0 0 1rem;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.joinDate {
  font-size: 12px;
  color: #95a5a6;
  margin: 0;
}

.loading, .error, .noData {
  font-family: 'Georgia', serif;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 3rem;
  color: #718096;
}

.error {
  color: #e53e3e;
}

:root {
  --primary-color: #2196f3;
  --secondary-color: #4caf50;
  --accent-color: #ff9800;
  --background-color: #f5f5f5;
  --text-color: #333333;
  --light-text-color: #ffffff;
  --dark-text-color: #222222;
  --font-family: 'Outfit', sans-serif;
  --body-font: 'Poppins', sans-serif;
  --title-font: 'Outfit', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.App {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #f7fafc;
}

.header {
  text-align: center;
  margin-bottom: 4rem;
}

.title {
  font-family: 'Georgia', serif;
  font-size: 3rem;
  font-weight: 700;
  color: #1a202c;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-family: 'Georgia', serif;
  font-size: 1.25rem;
  color: #4a5568;
}

.main {
  background-color: #fff;
}

.articleGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.articleItem {
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);

    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.articleLink {
  display: block;
  padding: 2rem;
  color: #1a202c;
  text-decoration: none;
  height: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #4a5568;
  }
}

.articleTitle {
  font-family: 'Georgia', serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.articleExcerpt {
  font-family: 'Georgia', serif;
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 1rem;
}

.articleMeta {
  font-family: 'Georgia', serif;
  font-size: 0.875rem;
  color: #718096;
}

.loadingMessage, .errorMessage {
  font-family: 'Georgia', serif;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 3rem;
}

.errorMessage {
  color: #e53e3e;
}
